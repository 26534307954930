function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

async function callAPI(url, method, postData) {
  const token = getCookie("__session");
  const req = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  if (["POST", "PUT", "UPDATE"].some((x) => x === method)) {
    req.body = JSON.stringify(postData);
  }
  const res = await fetch(url, req);
  const data = await res.json();
  if (res.ok) {
    return data;
  } else {
    return Promise.reject(data);
  }
}

const API_URL = (import.meta.env.VITE_API_URL || "") + "/api";

async function getMasters() {
  return await callAPI(`${API_URL}/public/masters/`, "GET");
}

async function getCompanyProfile() {
  return await callAPI(`${API_URL}/company/company_profile/`, "GET");
}

async function getCompanyPublicProfile(universal_name) {
  return await callAPI(
    `${API_URL}/company/company_public_profile?format=json&universal_name=${universal_name}`,
    "GET"
  );
}

async function registerWithEmailAndPassword(postData) {
  return await callAPI(`${API_URL}/users/create/`, "POST", postData);
}

async function loginWithEmailAndPassword(postData) {
  return await callAPI(`${API_URL}/auth/token/login/`, "POST", postData);
}

async function update_company_basic_records(postData) {
  return await callAPI(`${API_URL}/company/basic_records/`, "POST", postData);
}

async function setIndustryFocus(postData) {
  return await callAPI(
    `${API_URL}/company/company_industry_focus/`,
    "POST",
    postData
  );
}

async function setClientFocus(postData) {
  return await callAPI(
    `${API_URL}/company/company_client_focus/`,
    "POST",
    postData
  );
}

async function setCategoryL1(postData) {
  return await callAPI(`${API_URL}/company/category/l1/`, "POST", postData);
}
async function setCategoryL2(postData) {
  return await callAPI(`${API_URL}/company/category/l2/`, "POST", postData);
}
async function setCategoryL3(postData) {
  return await callAPI(`${API_URL}/company/category/l3/`, "POST", postData);
}

async function setClients(postData) {
  return await callAPI(`${API_URL}/company/company_clients/`, "POST", postData);
}
async function setProjects(postData) {
  return await callAPI(
    `${API_URL}/company/company_projects/`,
    "POST",
    postData
  );
}

async function setLocations(postData) {
  return await callAPI(
    `${API_URL}/company/company_locations/`,
    "POST",
    postData
  );
}

async function uploadPicture(file, pictureFor) {
  const mapper = {
    logo: `${API_URL}/company/company_logo_upload/`,
    cover: `${API_URL}/company/company_cover_upload/`,
  };
  const url = mapper[pictureFor];
  const token = getCookie("__session");
  const formData = new FormData();
  formData.append("image", file, file.name);
  let res = await fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
}

async function AdminCompanyProfile(company_universal_name) {
  return await callAPI(
    `${API_URL}/company/approvals_required/${company_universal_name}`,
    "GET"
  );
}

const getAllCompanies = async () => {
  return await callAPI(`${API_URL}/company/all_companies`, "GET");
};

const resetProfileCache = async (company_universal_name) => {
  return await callAPI(
    `${API_URL}/company/reset_profile_cache/${company_universal_name}/`,
    "GET"
  );
};

const clearAllRedisCache = async () => {
  return await callAPI(`${API_URL}/company/clear_all_redis_cache/`, "GET");
};

const clearBestListCache = async () => {
  return await callAPI(`${API_URL}/company/clear_best_list_cache/`, "GET");
};

async function updateStatus(postData) {
  return await callAPI(
    `${API_URL}/company/approve_data_update/`,
    "POST",
    postData
  );
}

async function requestApproval() {
  return await callAPI(
    `${API_URL}/company/request_approval_for_data_update/`,
    "GET"
  );
}

async function getCompanyReviews() {
  return await callAPI(`${API_URL}/company/get_company_reviews/`, "GET");
}

async function updateReviewStatusManager(postData) {
  return await callAPI(
    `${API_URL}/company/update_review_status_manager/`,
    "POST",
    postData
  );
}

async function updateReviewStatusGod(postData) {
  return await callAPI(
    `${API_URL}/company/update_review_status_god/`,
    "POST",
    postData
  );
}

async function createCompany(postData) {
  return await callAPI(`${API_URL}/company/create_company/`, "POST", postData);
}

async function companyJoinRequest(postData) {
  return await callAPI(
    `${API_URL}/company/company_join_request/`,
    "POST",
    postData
  );
}

async function getAllUsers() {
  return await callAPI(`${API_URL}/company/get_all_users/`, "GET");
}

async function updateMembershipRequest(postData) {
  return await callAPI(
    `${API_URL}/company/update_membership_request/`,
    "POST",
    postData
  );
}

async function updateMembership(postData) {
  return await callAPI(
    `${API_URL}/company/update_membership/`,
    "POST",
    postData
  );
}

async function getNotifications() {
  return await callAPI(`${API_URL}/company/get_notifications/`, "GET");
}

async function updateAllCompaniesLabsRating(postData) {
  return await callAPI(
    `${API_URL}/company/update_all_companies_labs_rating/`,
    "POST",
    postData
  );
}

async function getCompanyName(name) {
  return await callAPI(`${API_URL}/company/get_company_name/${name}`, "GET");
}

async function setReview(postData) {
  return await callAPI(`${API_URL}/company/set_review/`, "POST", postData);
}

async function getReviews(universalName) {
  return await callAPI(`${API_URL}/company/get_review/${universalName}`, "GET");
}

async function getAllReviews() {
  return await callAPI(`${API_URL}/company/get_all_reviews`, "GET");
}

async function getBestList(search) {
  const res = await callAPI(
    `${API_URL}/company/all_companies_public/`,
    "POST",
    {
      search: search,
    }
  );
  return res;
}

async function markNotificationRead() {
  return await callAPI(`${API_URL}/company/mark_notification_read/`, "GET");
}

export {
  registerWithEmailAndPassword,
  getCompanyProfile,
  getCompanyPublicProfile,
  loginWithEmailAndPassword,
  update_company_basic_records,
  setClients,
  setProjects,
  getMasters,
  setIndustryFocus,
  setClientFocus,
  setCategoryL1,
  setCategoryL2,
  setCategoryL3,
  uploadPicture,
  setLocations,
  getAllCompanies,
  AdminCompanyProfile,
  updateStatus,
  resetProfileCache,
  clearBestListCache,
  getCompanyReviews,
  updateReviewStatusManager,
  updateReviewStatusGod,
  createCompany,
  companyJoinRequest,
  requestApproval,
  getAllUsers,
  updateMembershipRequest,
  updateMembership,
  getNotifications,
  updateAllCompaniesLabsRating,
  getCompanyName,
  setReview,
  getReviews,
  getAllReviews,
  getBestList,
  markNotificationRead,
  clearAllRedisCache,
};
